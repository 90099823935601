// BetDataTable
/**
 * @author Fernando Mellone
 * @param {Number} game_id
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickBtnEstadistica(game_id, is_premium, golstats) {
  window.dataLayer.push({ event: 'click_btn_estadistica', game_id, is_premium, golstats });
}

/**
 * @author Fernando Mellone
 * @param {Boolean} value
 * @param {Boolean} is_premium
 * @param {Number} game_id
 * @return {{}}
 */

export function clickInputTipsInversion(value, is_premium, game_id, golstats) {
  let event = { event: 'click_input_tips_inversion', value, is_premium, game_id, golstats };

  window.dataLayer.push(event);
}

// TipsAnalysis
/**
 * @author Fernando Mellone
 * @param {Boolean} value
 * @param {Boolean} is_premium
 * @return {{}}
 */
export function clickBtnQuepasariasi(is_premium, value, golstats) {
  let event = {
    event: 'click_btn_quepasariasi',
    is_premium,
    golstats,
  };

  if (value !== undefined) event = { ...event, value };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} value
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickSwtichTipsQuepasariasi(is_premium, value, golstats) {
  let event = { event: 'click_swtich_tips_quepasariasi', is_premium, golstats };

  if (value !== undefined) event = { ...event, value };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickBtnTipsRentabilidad(is_premium, golstats) {
  let event = { event: 'click_btn_tips_rentabilidad', is_premium, golstats };

  window.dataLayer.push(event);
}

// MessagePremium
/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickBtnPopupLogin(is_premium, section, golstats) {
  let event = { event: 'click_btn_popup_login', is_premium, section, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickBtnPopupCrearCuenta(is_premium, section, golstats) {
  let event = { event: 'click_btn_popup_crear_cuenta', is_premium, section, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickBtnPopupCerrar(is_premium, golstats) {
  let event = { event: 'click_btn_popup_cerrar', is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickBtnBlackPopupCerrar(props) {
  let event = { event: 'close_black_popup', ...props };

  window.dataLayer.push(event);
}

// AdvanceFilter
/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @param {Number} game_id
 * @return {{}}
 */

export function clickBtnAplicarFiltros(is_premium, game_id, preview_section, golstats) {
  let event = { event: 'click_btn_aplicar_filtros', is_premium, game_id, preview_section, golstats };

  window.dataLayer.push(event);
}

// ItemNextMatches
/**
 * @author Fernando Mellone
 * @param {String} event_name
 * @param {Boolean} is_premium
 * @param {Boolean} is_live
 * @param {Number} game_id
 * @return {{}}
 */

export function ItemNextMatchesSelect(event_name, is_premium, is_live, game_id, preview_section, golstats) {
  let eventName = ``;
  switch (event_name) {
    case 'xg':
      eventName = `ver_expectativa_de_gol`;
      break;
    default:
      eventName = `click_btn_${event_name}`;
      break;
  }
  let event = { event: eventName, is_premium, is_live, game_id, preview_section, golstats };

  window.dataLayer.push(event);
}

// ProfileSidebar
/**
 * @author Fernando Mellone
 * @param {String} route_event
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function ProfileSidebarGoTo(route_event, is_premium, section, golstats) {
  let event = { event: route_event, is_premium, section, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @param {Number} game_id
 * @return {{}}
 */

export function clickBtnCrearCuenta(is_premium, golstats) {
  let event = { event: 'click_btn_crear_cuenta', is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickBtnLogin(is_premium, golstats) {
  let event = { event: 'click_btn_login', is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickBtnLogout(is_premium, golstats) {
  let event = { event: 'click_btn_logout', is_premium, golstats };

  window.dataLayer.push(event);
}

// TipsFilter
/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @param {String} value
 * @return {{}}
 */

export function clickSelectTipsRiesgo(is_premium, value, golstats) {
  let event = { event: 'click_select_tips_riesgo', value, is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @param {Number} value
 * @return {{}}
 * Included in SetBetAmount
 */

export function clickInputTipsMontoTotalApostar(is_premium, value, golstats) {
  let event = { event: 'click_input_tips_monto_total_apostar', value, is_premium, golstats };

  window.dataLayer.push(event);
}

// Video
/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickVideoFlujoCuentaPausa(is_premium, golstats) {
  let event = { event: 'click_video_flujo_cuenta_pausa', is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickVideoFlujoCuentaPlay(is_premium, golstats) {
  let event = { event: 'click_video_flujo_cuenta_play', is_premium, golstats };

  window.dataLayer.push(event);
}

// DesktopMenuNextMatches
/**
 * @author Fernando Mellone
 * @param {Number} game_id
 * @return {{}}
 */

export function btnVolver(params) {
  let event = { event: 'btn_volver', ...params };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Number} game_id
 * @param {Boolean} value
 * @return {{}}
 */

export function btnLive(game_id, value, is_premium, golstats) {
  let event = { event: 'btn_live', game_id, value, is_premium, golstats };
  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Number} game_id
 * @param {Boolean} is_live
 * @param {Boolean} is_premium
 * @param {String} value
 * @return {{}}
 * Included in MobileMenuNextMatches
 */

export function clickSelectTipoDeApuesta(game_id, is_live, value, is_premium, preview_section, golstats) {
  let event = {
    event: 'click_select_tipo_de_apuesta',
    game_id,
    is_live,
    value,
    is_premium,
    preview_section,
    golstats,
  };
  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Number} game_id
 * @param {Boolean} is_live
 * @param {Boolean} is_premium
 * @return {{}}
 * Included in MobileMenuNextMatches
 */

export function clickBtnAvanzado(game_id, is_live, is_premium, preview_section, golstats) {
  let event = { event: 'click_btn_avanzado', game_id, is_live, is_premium, preview_section, golstats };
  window.dataLayer.push(event);
}

// TendenciaLive
/**
 * @author Fernando Mellone
 * @param {Number} game_id
 * @param {Boolean} is_live
 * @param {String} value
 * @return {{}}
 */

export function clickSwitchTendenciaLiveEquipo(game_id, is_live, value, is_premium, golstats) {
  let event = { event: 'click_switch_tendencia_live_equipo', game_id, is_live, value, is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Number} game_id
 * @param {Boolean} is_live
 * @param {String} value
 * @return {{}}
 */

export function clickSelectTendenciaLiveSelectorEstadistica(
  game_id,
  is_live,
  value,
  is_premium,
  preview_section,
  golstats,
) {
  let event = {
    event: 'click_select_tendencia_live_selector_estadistica',
    game_id,
    is_live,
    value,
    is_premium,
    preview_section,
    golstats,
  };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Number} game_id
 * @param {Boolean} is_live
 * @param {String} value
 * @param {Boolean} is_premium
 * @param {Boolean} golstats
 * @return {{}}
 */

export function clickSelectTendenciaLiveSelectorTipoVista(
  game_id,
  is_live,
  value,
  is_premium,
  preview_section,
  golstats,
) {
  let event = {
    event: 'click_select_tendencia_live_selector_tipo_vista',
    game_id,
    is_live,
    value,
    is_premium,
    preview_section,
    golstats,
  };

  window.dataLayer.push(event);
}

// ChooseRisk
/**
 * @author Fernando Mellone
 * @param {Number} value
 * @param {Boolean} is_premium
 * @param {Boolean} golstats
 * @return {{}}
 */

export function clickBtnEligeRiesgoSiguiente(value, is_premium, golstats) {
  let event = { event: 'click_btn_elige_riesgo_siguiente', value, is_premium, golstats };

  window.dataLayer.push(event);
}

// Home
/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @param {Boolean} golstats
 * @return {{}}
 */

export function clickBtnBienvenidoLograrGanancia(is_premium, golstats) {
  let event = { event: 'click_btn_bienvenido_lograr_ganancia', is_premium, golstats };

  window.dataLayer.push(event);
}

// Matches
/**
 * @author Fernando Mellone
 * @param {Number} game_id
 * @param {Boolean} is_premium
 * @param {Boolean} golstats
 * @return {{}}
 */

export function clickBtnPartido(game_id, is_premium, golstats) {
  let event = { event: 'click_btn_partido', game_id, is_premium, golstats };

  window.dataLayer.push(event);
}

// SetBetAmount
/**
 * @author Fernando Mellone
 * @param {Number} value
 * @param {Boolean} is_premium
 * @param {Boolean} golstats
 * @return {{}}
 */

export function clickBtnMontoSiguiente(value, is_premium, golstats) {
  let event = { event: 'click_btn_monto_siguiente', value, is_premium, golstats };

  window.dataLayer.push(event);
}

// SetBetUser
/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @param {Boolean} golstats
 * @return {{}}
 */

export function clickBtnCamnioUsuarioPremium(is_premium, golstats) {
  let event = { event: 'click_btn_camnio_usuario_premium', is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} is_premium
 * @return {{}}
 */

export function clickBtnCaminoUsuarioFree(is_premium, golstats) {
  let event = { event: 'click_btn_camino_usuario_free', is_premium, golstats };

  window.dataLayer.push(event);
}

// CardForm
/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickBtnFlujoCuentaFinalizarCompra(params) {
  let event = { event: 'click_btn_flujo_cuenta_finalizar_compra', ...params };

  window.dataLayer.push(event);
}

// Auth/Index
/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickBtnFlujoCuentaCerrar(is_premium, golstats) {
  let event = { event: 'click_btn_flujo_cuenta_cerrar', is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickBtnFlujoCuentaIniciarSesionP(is_premium, golstats) {
  let event = { event: 'click_btn_flujo_cuenta_iniciar_sesion_p', is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickBtnFlujoCuentaCrearCuentaP(is_premium, golstats) {
  let event = { event: 'click_btn_flujo_cuenta_crear_cuenta_p', is_premium, golstats };

  window.dataLayer.push(event);
}

// Login
/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickBtnFlujoIniciarSesion(is_premium, golstats) {
  let event = { event: 'click_btn_flujo_iniciar_sesion', is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickLogin(section) {
  let event = { event: 'login', ...section };

  window.dataLayer.push(event);
}

export function clickLoginBlackPopUp(section) {
  let event = { event: 'login_black_popup', ...section };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickBtnFlujoCuentaCrearUna(is_premium, golstats) {
  let event = { event: 'click_btn_flujo_cuenta_crear_una', is_premium, golstats };

  window.dataLayer.push(event);
}

// Register
/**
 * @author Fernando Mellone
 * @param {Boolean} value
 * @return {{}}
 */

export function clickBtnFlujoCuentaCrearCuentaSig(params) {
  let event = { event: 'click_btn_flujo_cuenta_crear_cuenta_sig', ...params };

  window.dataLayer.push(event);
}

export function clickBtnFlujoCuentaCrearCuentaSig2(params) {
  let event = { event: 'click_btn_flujo_cuenta_crear_cuenta_sig_2', ...params };
  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Boolean} value
 * @return {{}}
 */

export function clickBtnFlujoCuentaExisteIniciarS(value, is_premium, golstats) {
  let event = { event: 'click_btn_flujo_cuenta_existe_iniciar_s', value, is_premium, golstats };

  window.dataLayer.push(event);
}

// SelectPlan
/**
 * @author Fernando Mellone
 * @param {String} value
 * @return {{}}
 */

export function clickBtnFlujoCuentaSeleccionarPlan(value, is_premium, golstats) {
  let event = { event: 'click_btn_flujo_cuenta_seleccionar_plan', value, is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {Number} plan_id
 * @param {String} plan_name
 * @param {Number} plan_price
 * @param {String} plan_currency
 * @return {{}}
 */

export function clickBtnFlujoCuentaSeleccionar(params) {
  let event = { event: 'click_btn_flujo_cuenta_seleccionar', ...params };

  window.dataLayer.push(event);
}

// AvailablePlans
/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickBtnPlanesDisponiblesCancelar(is_premium, golstats) {
  let event = { event: 'click_btn_planes_disponibles_cancelar', is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @param {String} plan_name
 * @return {{}}
 */

export function clickBtnPlanesDisponiblesPorNombre(plan_name, is_premium, golstats) {
  let event = { event: `click_btn_planes_disponibles_${plan_name}`, is_premium, golstats };

  window.dataLayer.push(event);
}

// Profile/Settings/Index
/**
 * @author Fernando Mellone
 * @param {String} route_name
 * @return {{}}
 */

export function clickBtnPorRuta(route_name, is_premium, golstats) {
  let event = { event: `click_btn_${route_name}`, is_premium, golstats };

  window.dataLayer.push(event);
}

// PaymentMethods
/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickBtnMetodoDePagoPrincipal(is_premium, golstats) {
  let event = { event: `click_btn_metodo_de_pago_principal`, is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone
 * @return {{}}
 */

export function clickBtnMetodoDePagoEliminar(is_premium, golstats) {
  let event = { event: `click_btn_metodo_de_pago_eliminar`, is_premium, golstats };

  window.dataLayer.push(event);
}

/**
 * @author Fernando Mellone

 * @return {{}}
 */

export function clickBtnMetodoDePagoAgregarTarjeta(is_premium, golstats) {
  let event = { event: `click_btn_metodo_de_pago_agregar_tarjeta`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickTipsCientificosParaApostar(is_premium, golstats) {
  let event = { event: `ver_mas_informacion_de_tips_cientificos`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickQueEsGolStats(is_premium, golstats) {
  let event = { event: `ver_video_que_es_golstats_desde_tips`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickComoFunciona(is_premium, golstats) {
  let event = { event: `ver_video_como_funciona_desde_tips`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickTop4ApuestasDelMomento(is_premium, golstats) {
  let event = { event: `tips_top_4_apuestas_del_momento`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickTipsUsuarioFree(is_premium, golstats) {
  let event = { event: `click_boton_usuario_free`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickTipsVerMasInformacionDeMonto(is_premium, golstats) {
  let event = { event: `ver_mas_informacion_de_monto`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickVerMasInformacionDeTiposDeRiesgo(is_premium, golstats) {
  let event = { event: `ver_mas_informacion_de_tipos_de_riesgo`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickVerMasInformacionDeEstrategiaDeCombinacionDeApuestas(is_premium, golstats) {
  let event = { event: `ver_mas_info_de_estrategia_de_apuestas`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickCrearCuentaPremium(params) {
  let event = { event: `crear_cuenta_premium`, ...params };

  window.dataLayer.push(event);
}

export function clickVerTerminosDeUsoDesdeFooter(is_premium, golstats) {
  let event = { event: `ver_terminos_de_uso_desde_footer`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickEstadisticaTipoDeApuestaVerInformacion(is_premium, preview_section, golstats) {
  let event = { event: `ver_mas_informacion_de_estadisticas`, is_premium, preview_section, golstats };

  window.dataLayer.push(event);
}

export function clickEstadisticaComoFunciona(is_premium, preview_section, golstats) {
  let event = { event: `ver_video_como_funciona_en_estadisticas`, is_premium, preview_section, golstats };

  window.dataLayer.push(event);
}

export function clickTipsRegresarARendimientoOptimo(is_premium, golstats) {
  let event = { event: `regresar_a_rendimiento_optimo`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function ClickTipsTablaVerInformacion(is_premium, golstats) {
  let event = { event: `ver_mas_informacion_acerca_de_los_tips`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickOlvidasteLaContrasena(is_premium, golstats) {
  let event = { event: `recuperar_contraseña`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickEnviarRecuperarContrasena(is_premium, golstats) {
  let event = { event: `enviar_email_recuperar_contraseña`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickConfirmarCambioDeContrasena(is_premium, golstats) {
  let event = { event: `confirmar_cambio_de_contraseña`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickEnviarMensajeDeContacto(is_premium, golstats) {
  let event = { event: `enviar_mensaje_de_contacto`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickVerExpectativaDeGolDeEquipo(params) {
  let event = { event: `ver_expectativa_de_gol_de_equipo`, ...params };

  window.dataLayer.push(event);
}

export function clickVerVideoQueEsXg(is_premium, preview_section, golstats) {
  let event = { event: `ver_video_que_es_xg`, is_premium, preview_section, golstats };

  window.dataLayer.push(event);
}

export function clickVerEjemploInformeEstadistico(is_premium, golstats) {
  let event = { event: `ver_ejemplo_informe_estadistico`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickVerEjemploDeRedesSociales(is_premium, golstats) {
  let event = { event: `ver_ejemplo_de_redes_sociales`, is_premium, golstats };

  window.dataLayer.push(event);
}

export function clickTopsAnalisisDeEquipo(params) {
  window.dataLayer.push({ event: 'widget_tops_analisis_de_equipo', ...params });
}

export function clickTopsAnalisisDeJugador(params) {
  window.dataLayer.push({ event: 'widget_tops_analisis_de_jugador', ...params });
}

export function clickFiltrarTops(params) {
  window.dataLayer.push({ event: 'widget_tops_filtrar', ...params });
}

export function clickFiltrarAvanzadoTops(params) {
  window.dataLayer.push({ event: 'widget_tops_filtrar_avanzado', ...params });
}

export function widgetSeccionVista(params) {
  window.dataLayer.push({ event: 'widget_seccion_vista', ...params });
}

export function widgetApostadoresParlayApostando(params) {
  window.dataLayer.push({ event: 'widget_apostadores_parlayApostando', ...params });
}

export function widgetApostadoresParlayModificar(params) {
  window.dataLayer.push({ event: 'widget_apostadores_parlay_modificarParlay', ...params });
}

export function widgetApostadoresParlayModificarRiesgo(params) {
  window.dataLayer.push({ event: 'widget_apostadores_parlay_modificarParlay_riesgo', ...params });
}

export function widgetApostadoresParlayModificarAgregar(params) {
  window.dataLayer.push({ event: 'widget_apostadores_parlay_modificarParlay_agregar', ...params });
}

export function widgetApostadoresParlayModificarRiesgoCerrar(params) {
  window.dataLayer.push({ event: 'widget_apostadores_parlay_modificarParlay_riesgo_cerrar', ...params });
}

export function widgetApostadoresModificarParlayDeselecEquipo(params) {
  window.dataLayer.push({ event: 'widget_apostadores_parlay_modificarParlay_deseleccionarEquipo', ...params });
}

export function widgetApostadoresModificarParlaySelecEquipo(params) {
  window.dataLayer.push({ event: 'widget_apostadores_parlay_modificarParlay_seleccionarEquipo', ...params });
}

export function widgetApostadoresModificarParlayAplicar(params) {
  window.dataLayer.push({ event: 'widget_apostadores_parlay_modificarParlay_aplicarCambios', ...params });
}

export function widgetApostadoresModificarParlayCancelar(params) {
  window.dataLayer.push({ event: 'widget_apostadores_parlay_modificarParlay_cancelar', ...params });
}

export function widgetApostadoresApuestaDerechaJornadaYPartido(params) {
  window.dataLayer.push({ event: 'widget_apostadores_apuestaDerecha_jornadaYPartido', ...params });
}

export function widgetApostadoresApuestaDerechaVerMasEstadisticas(params) {
  window.dataLayer.push({ event: 'widget_apostadores_apuestaDerecha_verMasEstadisticas', ...params });
}

export function widgetApostadoresTipsSelectTorneo(params) {
  window.dataLayer.push({ event: 'WA_tips_select_torneo', ...params });
}

export function widgetApostadoresClickParlay(params) {
  window.dataLayer.push({ event: 'WA_click_parlay', ...params });
}

export function widgetApostadoresClickApuestaDerecha(params) {
  window.dataLayer.push({ event: 'WA_click_apuestaderecha', ...params });
}

export function widgetApostadoresClickTips(params) {
  window.dataLayer.push({ event: 'WA_click_tips', ...params });
}

export function freePicksRightBet(params) {
  window.dataLayer.push({ event: 'free_picks_btn_apuesta_derecha', ...params });
}

export function freePicksParlay(params) {
  window.dataLayer.push({ event: 'free_picks_btn_parlay', ...params });
}

export function freePicksSimulator(params) {
  window.dataLayer.push({ event: 'free_picks_btn_simulador', ...params });
}

export function freePicksPersonalized(params) {
  window.dataLayer.push({ event: 'free_picks_btn_personalizado', ...params });
}

export function freePicksSwipeLeft(params) {
  window.dataLayer.push({ event: 'free_picks_apuesta_derecha_swipe_left', ...params });
}

export function freePicksSwipeRight(params) {
  window.dataLayer.push({ event: 'free_picks_apuesta_derecha_swipe_right', ...params });
}

export function personalizedBetResult(params) {
  window.dataLayer.push({ event: 'personalizado_apuesta_resultado', ...params });
}

export function personalizedBetCorners(params) {
  window.dataLayer.push({ event: 'personalizado_apuesta_tiros_de_esquina', ...params });
}

export function personalizedBetGoals(params) {
  window.dataLayer.push({ event: 'personalizado_apuesta_goles', ...params });
}

export function personalizedBetHalf(params) {
  window.dataLayer.push({ event: 'personalizado_apuesta_mitad', ...params });
}

export function personalizedBetParlay(params) {
  window.dataLayer.push({ event: 'personalizado_apuesta_parlay', ...params });
}

export function personalizedTips(params) {
  window.dataLayer.push({ event: 'personalizado_tips', ...params });
}

export function goltipsterRightBet(params) {
  window.dataLayer.push({ event: 'goltipster_apuesta_derecha', ...params });
}

export function goltipsterParlay(params) {
  window.dataLayer.push({ event: 'goltipster_parlay', ...params });
}

export function goltipsterTips(params) {
  window.dataLayer.push({ event: 'goltipster_tips', ...params });
}

export function goltipsterLive(params) {
  window.dataLayer.push({ event: 'goltipster_live', ...params });
}

export function RightBetSelecLeague(params) {
  window.dataLayer.push({ event: 'ad_selec_liga', ...params });
}

export function RightBetSelecMatchday(params) {
  window.dataLayer.push({ event: 'ad_selec_jornada', ...params });
}

export function RightBetSelecGame(params) {
  window.dataLayer.push({ event: 'ad_selec_partido', ...params });
}

export function RightBetCarrouselViewProfile(params) {
  window.dataLayer.push({ event: 'ad_carrusel_ver_perfil', ...params });
}

export function RightBetCarrouselViewStats(params) {
  window.dataLayer.push({ event: 'ad_carrusel_ver_estadisticas', ...params });
}

export function RightBetCarrouselNext(params) {
  window.dataLayer.push({ event: 'ad_carrusel_siguiente', ...params });
}

export function RightBetCarrouselBefore(params) {
  window.dataLayer.push({ event: 'ad_carrusel_anterior', ...params });
}

export function RightBetLocality(params) {
  window.dataLayer.push({ event: 'ad_localia', ...params });
}

export function RightBetTime(params) {
  window.dataLayer.push({ event: 'ad_tiempo', ...params });
}

export function RightBetCorners(params) {
  window.dataLayer.push({ event: 'ad_tiros_esquina', ...params });
}

export function RightBetResult(params) {
  window.dataLayer.push({ event: 'ad_resultado', ...params });
}

export function RightBetGoals(params) {
  window.dataLayer.push({ event: 'ad_goles', ...params });
}

export function parlayStrategy(params) {
  window.dataLayer.push({ event: 'parlay_estrategia', ...params });
}

export function parlayBreakdown(params) {
  window.dataLayer.push({ event: 'parlay_desglose', ...params });
}

export function parlayBreakdownSeeMore(params) {
  window.dataLayer.push({ event: 'parlay_desglose_ver_mas', ...params });
}

export function parlayBreakdownSeeLess(params) {
  window.dataLayer.push({ event: 'parlay_desglose_ver_menos', ...params });
}

export function parlayDetails(params) {
  window.dataLayer.push({ event: 'parlay_detalle', ...params });
}

export function parlayValueBetting(params) {
  window.dataLayer.push({ event: 'parlay_value_betting', ...params });
}

export function parlay2Games1(params) {
  window.dataLayer.push({ event: 'parlay_2_partidos_riesgo_bajo', ...params });
}

export function parlay2Games2(params) {
  window.dataLayer.push({ event: 'parlay_2_partidos_riesgo_medio', ...params });
}

export function parlay3Games(params) {
  window.dataLayer.push({ event: 'parlay_3_partidos_riesgo_medio', ...params });
}

export function parlay4Games(params) {
  window.dataLayer.push({ event: 'parlay_4_partidos_riesgo_alto', ...params });
}

export function parlayBetAmount(params) {
  window.dataLayer.push({ event: 'parlay_apostando_monto', ...params });
}

export function tipsStrategy(params) {
  window.dataLayer.push({ event: 'tips_estrategia', ...params });
}

export function tipsBreakdown(params) {
  window.dataLayer.push({ event: 'tips_desglose', ...params });
}

export function tipsBreakdownSeeMore(params) {
  window.dataLayer.push({ event: 'tips_ver_mas', ...params });
}

export function tipsBreakdownSeeLess(params) {
  window.dataLayer.push({ event: 'tips_ver_menos', ...params });
}

export function tipsDetails(params) {
  window.dataLayer.push({ event: 'tips_ver_detalle', ...params });
}

export function tipsDiversification(params) {
  window.dataLayer.push({ event: 'tips_diversificacion', ...params });
}

export function tipsSimulator(params) {
  window.dataLayer.push({ event: 'tips_simulador', ...params });
}

export function tipsBetAmount(params) {
  window.dataLayer.push({ event: 'tips_apostando_monto', ...params });
}

export function doBetcaliente(params) {
  window.dataLayer.push({ event: 'realiza_apuesta_caliente', ...params });
}

export function BannerOurSuggestions(params) {
  window.dataLayer.push({ event: 'banner_nuestras_sugerencias', ...params });
}

export function BannerScientist(params) {
  window.dataLayer.push({ event: 'banner_apostador_cientifico', ...params });
}

export function BannerTipScientist(params) {
  window.dataLayer.push({ event: 'banner_tip_cientifico', ...params });
}

export function BannerParlay(params) {
  window.dataLayer.push({ event: 'banner_parlay', ...params });
}

export function BannerFreePicks(params) {
  window.dataLayer.push({ event: 'banner_free_picks', ...params });
}
export function BannerTechBet(params) {
  window.dataLayer.push({ event: 'banner_tecnologia_apostar', ...params });
}
